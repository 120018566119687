import { createSlice } from "@reduxjs/toolkit";
import {
  getAllInventoryItems,
  createInventoryItem,
  getInventoryByCustomerId,
  deleteInventory,
  getInventoryById,
  updateInventory,
  getInventoryByItemName,
  getInventoryByTemplateId,
  getAllInventoryTemplates,
  createInventoryTemplateItem,
  deleteInventoryTemplateById,
  getInventoryTemplateById,
  updateInventoryTemplateById,
  getTotalsOfInventoryItems,
  resetTemplateStates,
  resetInventoryStates,
  isInventoryItemAvailableById,
  isInventoryItemAvailableBySerial1,
} from './thunk';

export const initialState = {
  inventoryItems: [],
  inventoryItem: {},
  inventoryTemplates: [],
  inventoryTemplate: {},
  inventoryTotals: [],
  error: {},
};

const InventorySlice = createSlice({
  name: 'InventorySlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getAllInventoryItems.fulfilled, (state, { payload }) => {
      state.inventoryItems = payload;
    });
    builder.addCase(getAllInventoryItems.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(createInventoryItem.fulfilled, (state, { payload }) => {
      state.inventoryItem = payload;
    });
    builder.addCase(createInventoryItem.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getInventoryByCustomerId.fulfilled, (state, { payload }) => {
      state.inventoryItems = payload;
    });
    builder.addCase(getInventoryByCustomerId.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(deleteInventory.fulfilled, (state, { payload }) => {
      state.inventoryItem = payload;
    });
    builder.addCase(deleteInventory.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getInventoryById.fulfilled, (state, { payload }) => {
      state.inventoryItem = payload;
    });
    builder.addCase(getInventoryById.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(updateInventory.fulfilled, (state, { payload }) => {
      state.inventoryItem = payload;
    });
    builder.addCase(updateInventory.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getInventoryByItemName.fulfilled, (state, { payload }) => {
      state.inventoryItems = payload;
    });
    builder.addCase(getInventoryByItemName.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getInventoryByTemplateId.fulfilled, (state, { payload }) => {
      state.inventoryItems = payload;
    });
    builder.addCase(getInventoryByTemplateId.rejected, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(getAllInventoryTemplates.fulfilled, (state, { payload }) => {
      state.inventoryTemplates = payload;
    });
    builder.addCase(getAllInventoryTemplates.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(createInventoryTemplateItem.fulfilled, (state, { payload }) => {
      state.inventoryTemplate = payload;
    });
    builder.addCase(createInventoryTemplateItem.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(deleteInventoryTemplateById.fulfilled, (state, { payload }) => {
      state.inventoryTemplate = payload;
    });
    builder.addCase(deleteInventoryTemplateById.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getInventoryTemplateById.fulfilled, (state, { payload }) => {
      state.inventoryTemplate = payload;
    });
    builder.addCase(getInventoryTemplateById.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(updateInventoryTemplateById.fulfilled, (state, { payload }) => {
      state.inventoryTemplate = payload;
    });
    builder.addCase(updateInventoryTemplateById.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getTotalsOfInventoryItems.fulfilled, (state, { payload }) => {
      state.inventoryTotals = payload;
    });
    builder.addCase(getTotalsOfInventoryItems.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(resetTemplateStates.fulfilled, (state) => {
      state.inventoryTemplate = {};
      state.inventoryTemplates = [];
      state.inventoryTotals = [];
    });
    builder.addCase(resetInventoryStates.fulfilled, (state) => {
      state.inventoryItem = {};
      state.inventoryItems = [];
    });

    builder.addCase(isInventoryItemAvailableById.fulfilled, (state, { payload }) => {
      state.isInventoryAvailable = payload;
    });
    builder.addCase(isInventoryItemAvailableById.rejected, (state, { payload }) => {
      state.isInventoryAvailableError = payload;
    });

    builder.addCase(isInventoryItemAvailableBySerial1.fulfilled, (state, { payload }) => {
      state.isInventoryAvailable = payload;
    });
    builder.addCase(isInventoryItemAvailableBySerial1.rejected, (state, { payload }) => {
      state.isInventoryAvailableError = payload;
    });
  }
});

export default InventorySlice.reducer;