import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import InventoryTable from './InventoryTable';
import AsyncSelect from 'react-select/async';
import DeleteModal from "../../../Components/Common/DeleteModal";

import { updateService, getInventoryByCustomerId, getCustomers, deleteService } from '../../../slices/thunks';

const ServiceView = ({ service, onClose = null, customerId = null, type = null }) => {
    const dispatch = useDispatch();

    // Initialize state with all service fields
    const [formData, setFormData] = useState({
        service_name: '',
        service_type: '',
        service_uuid: '',
        service_notes: '',
        retail_cost: 0,
        wholesale_cost: 0,
        service_taxable: false,
        service_visible_to_customer: false,
        service_active_date: null,
        invoiced: false,
        service_id: null,
        service_deactivate_date: null,
        customer_id: null,
        service_billed: false,
        service_status: '',
        bundled_parent: null,
        deprovisioning_play: null,
        auto_renew: false,
        addon_parent: null,
        deprovisioning_json_vars: null,
        promo_code: '',
        site_id: null,
        service_usage_visible_to_customer: false,
        contract_end_date: '',
        last_modified: '',
        product_id: null,
        service_provisioned_date: '',
        created: '',
        icon: ''
    });

    const [inventory, setInventory] = useState([]);
    const [transferModal, setTransferModal] = useState(false);
    const [customerIdForTransfer, setCustomerIdForTransfer] = useState([]);
    const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    // Handle form input changes
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleServiceTransfer = () => {
        const updatedService = {
            ...service,
            ...formData,
            customer_id: customerIdForTransfer?.value
        }

        dispatch(updateService(updatedService))
            .then(() => {
                toast.success('Service successfully transfered');
            })
            .catch((error) => {
                toast.error('Failed to transfer Service');
                console.error(error);
            })
            .finally(() =>{
                setTransferModal(false);
                if (onClose) {
                    onClose();
                }
            });
    };

    const loadOptions = (inputValue, callback) => {
        dispatch(getCustomers({ searchTerm: inputValue }))
            .then((response) => {
                const options = response.payload.data
                    .filter(customer => customer.customer_id !== service.customer_id) // filter out current service's customer id
                    .map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
                
                callback(options);
            });
    };

    const handleDeleteServiceConfirm = () => {
        dispatch(deleteService(service.service_id))
            .then(() => {
                toast.success("Service deleted successfully");
                setDeleteModal(false);
            })
            .catch((error) => {
                toast.error('Failed to delete Service');
                console.error(error);
            })
            .finally(() => {
                if (onClose) {
                    onClose();
                }
            });
      };

    // Handle form submission
    const handleUpdate = (event) => {
        event.preventDefault();

        const updatedService = {
            ...service,
            ...formData,
        };

        dispatch(updateService(updatedService))
            .then(() => {
                toast.success('Service updated successfully');
            })
            .catch((error) => {
                toast.error('Failed to update service');
                console.error(error);
            })
            .finally(() => {
                if (onClose) {
                    onClose();
                }
            });
    };

    // Fetch inventory data based on customer and service ID
    const getInventoryByCustomerIDAndServiceID = (customer_id, service_id) => {
        const filters = { service_id: [service_id] };
        dispatch(getInventoryByCustomerId({ customerId: customer_id, filters }))
            .then((response) => {
                const inventory = response.payload.data;
                setInventory(inventory);
            });
    };

    // Helper to format date to YYYY-MM-DD
    const formatDate = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
    };

    // Update formData when service prop changes
    useEffect(() => {
        if (!service) {
            return;
        }
        setFormData({
            service_name: service.service_name || '',
            service_type: service.service_type || '',
            service_uuid: service.service_uuid || '',
            service_notes: service.service_notes || '',
            retail_cost: service.retail_cost || 0,
            wholesale_cost: service.wholesale_cost || 0,
            service_taxable: service.service_taxable || false,
            service_visible_to_customer: service.service_visible_to_customer || false,
            service_active_date: service.service_active_date || '',
            invoiced: service.invoiced || false,
            service_id: service.service_id || null,
            service_deactivate_date: service.service_deactivate_date || '',
            customer_id: service.customer_id || null,
            service_billed: service.service_billed || false,
            service_status: service.service_status || '',
            bundled_parent: service.bundled_parent || null,
            deprovisioning_play: service.deprovisioning_play || null,
            auto_renew: service.auto_renew || false,
            addon_parent: service.addon_parent || null,
            deprovisioning_json_vars: service.deprovisioning_json_vars || null,
            promo_code: service.promo_code || '',
            site_id: service.site_id || null,
            service_usage_visible_to_customer: service.service_usage_visible_to_customer || false,
            contract_end_date: service.contract_end_date || '',
            last_modified: service.last_modified || '',
            product_id: service.product_id || null,
            service_provisioned_date: service.service_provisioned_date || '',
            created: service.created || '',
            icon: service.icon || ''
        });

        if (service.customer_id && service.service_id) {
            getInventoryByCustomerIDAndServiceID(service.customer_id, service.service_id);
        }
    }, [service]);

    return (
        <React.Fragment>
            <Form onSubmit={handleUpdate}>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="service_name">Service Name</Label>
                            <Input type="text" name="service_name" id="service_name" value={formData.service_name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_type">Service Type</Label>
                            <Input type="text" name="service_type" id="service_type" value={formData.service_type} onChange={handleChange} disabled />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_uuid">Service UUID</Label>
                            <Input type="text" name="service_uuid" id="service_uuid" value={formData.service_uuid} onChange={handleChange} disabled />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_notes">Service Notes</Label>
                            <Input type="textarea" name="service_notes" id="service_notes" value={formData.service_notes} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_provisioned_date">Service Provisioned Date</Label>
                            <Input type="date" name="service_provisioned_date" id="service_provisioned_date" value={formatDate(formData.service_provisioned_date)} onChange={handleChange} disabled/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_active_date">Service Active Date</Label>
                            <Input type="date" name="service_active_date" id="service_active_date" value={formatDate(formData.service_active_date)} onChange={handleChange} disabled/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_deactivate_date">Service Deactivate Date</Label>
                            <Input type="date" name="service_deactivate_date" id="service_deactivate_date" value={formatDate(formData.service_deactivate_date)} onChange={handleChange} disabled/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="contract_end_date">Contract End Date</Label>
                            <Input type="date" name="contract_end_date" id="contract_end_date" value={formatDate(formData.contract_end_date)} onChange={handleChange} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="retail_cost">Retail Cost</Label>
                            <Input type="number" name="retail_cost" id="retail_cost" value={formData.retail_cost} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="wholesale_cost">Wholesale Cost</Label>
                            <Input type="number" name="wholesale_cost" id="wholesale_cost" value={formData.wholesale_cost} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_taxable">Service Taxable</Label>{' '}
                            <Input type="checkbox" name="service_taxable" id="service_taxable" checked={formData.service_taxable} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_visible_to_customer">Service Visible To Customer</Label>{' '}
                            <Input type="checkbox" name="service_visible_to_customer" id="service_visible_to_customer" checked={formData.service_visible_to_customer} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_usage_visible_to_customer">Service Usage Visible To Customer</Label>{' '}
                            <Input type="checkbox" name="service_usage_visible_to_customer" id="service_usage_visible_to_customer" checked={formData.service_usage_visible_to_customer} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="auto_renew">Auto Renew</Label>{' '}
                            <Input type="checkbox" name="auto_renew" id="auto_renew" checked={formData.auto_renew} onChange={handleChange} />
                        </FormGroup>

                        <InventoryTable inventory={inventory} />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-end gap-2'>
                        {type !== 'self-care' && (
                            <>
                                <Button className='btn btn-danger' type='button' onClick={() => setDeleteModal(true)}>Delete</Button>
                                <Button className='btn btn-primary' type="button" onClick={() => setTransferModal(true)}>Transfer Service</Button>
                            </>
                        )}
                        <Button className='btn btn-success' type="submit">Save</Button>
                    </Col>
                </Row>
            </Form>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => {
                handleDeleteServiceConfirm();
                setDeleteModal(false);
                }}
                onCloseClick={() => setDeleteModal(false)}
            />
            {type !== 'self-care' && (
                <Modal id="transferModal" isOpen={transferModal} toggle={() => { setTransferModal(!transferModal); }} centered>
                    <ModalHeader toggle={() => { setTransferModal(!transferModal); }}>
                        Transfer Service
                    </ModalHeader>
                    <ModalBody>
                    <Label className='mb-2'>Select a Customer</Label>
                        <AsyncSelect
                            loadOptions={loadOptions}
                            onChange={(selectedOption) => {
                                setCustomerIdForTransfer(selectedOption);
                                setSelectCustomerValidation(selectedOption.value !== null);
                            }}
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 2 })
                            }}
                        />
                        {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={() => setTransferModal(false)}>Cancel</button>
                        <button type="button" className="btn btn-success" onClick={handleServiceTransfer}>Transfer</button>
                    </ModalFooter>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default ServiceView;
