import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
// import Dashboard from "../pages/Dashboard";
// import DashboardUpdates from "../pages/DashboardUpdates";
// import DashboardReports from "../pages/DashboardReports";

// Customer
import CustomerList from "../pages/Customer/CustomerList";
// import CustomerDetails from "../pages/Customer/CustomerDetails";
import CustomerCreate from "../pages/Customer/CustomerCreate";
import CustomerOverview from "../pages/Customer/CustomerOverview";
// import CustomerEdit from "../pages/Customer/CustomerEdit";

// Services
import ServiceList from "../pages/Services/ServiceList";
// import ServiceCreate from "../pages/Services/ServiceCreate";
import AddServiceToCustomer from "../pages/Services/AddServiceToCustomer";

// Support
// import SupportTicketList from "../pages/Support/Ticket/TicketList";

// Billing
  // Invoices
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceCreate from "../pages/Invoices/InvoiceCreate";
import InvoiceDetails from "../pages/Invoices/InvoiceDetails";

// import SuspendedCustomers from "../pages/Billing/SuspendedCustomers";
// import Transactions from "../pages/Billing/Transactions";
// import Payments from "../pages/Billing/Payments";
// import CreditCards from "../pages/Billing/CreditCards";

// Provisioning
// import ProvisioningList from "../pages/Provisioning/ProvisioningList";
// import ProvisionedServices from "../pages/Provisioning/ProvisionedServices";
// import DeprovisionedServices from "../pages/Provisioning/DeprovisionedServices";

// Communications
// import EmailList from "../pages/Communications/Email/EmailList";
// import AllCustomerBroadcasts from "../pages/Communications/Broadcast/AllCustomerBroadcasts";
// import MobileCustomerBroadcasts from "../pages/Communications/Broadcast/MobileCustomerBroadcasts";
// import VoiceCustomerBroadcasts from "../pages/Communications/Broadcast/VoiceCustomerBroadcasts";
// import InternetCustomerBroadcasts from "../pages/Communications/Broadcast/InternetCustomerBroadcasts";
// import EmergencyCellBroadcasts from "../pages/Communications/Broadcast/EmergencyCellBroadcasts";

// Inventory
import InventoryList from "../pages/Inventory/InventoryList";
// import InventoryDetails from "../pages/Inventory/InventoryDetails";
import InventoryCreate from "../pages/Inventory/InventoryCreate";
// import InventoryTemplates from "../pages/Inventory/InventoryTemplates";
import TemplateList from "../pages/Inventory/TemplateList";
// import TemplateDetailList from "../pages/Inventory/components/TemplateDetailList";

// Provisioning
import RecentProvisioningJobs from "../pages/Provisioning/RecentProvisioningJobs";
import FailedProvisioningJobs from "../pages/Provisioning/FailedProvisioningJobs";
import RecentDeprovisioningJobs from "../pages/Provisioning/RecentDeprovisioningJobs";

// Plans & Products
import PlanList from "../pages/Product/PlanList";
// import Promotions from "../pages/Plans/Promotions";
// import AddonPacks from "../pages/Plans/AddonPacks";

// Reporting & Analytics
// import Reports from "../pages/Reports/Reports";

// Notifications
// import Notifications from "../pages/Notifications/Notifications";

// Settings
// import GeneralSettings from "../pages/Settings/GeneralSettings";
// import Profile from "../pages/Settings/Profile";




//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

// Support Tickets
import ListView from '../pages/SupportTickets/ListView';
import TicketsDetails from '../pages/SupportTickets/TicketsDetails';

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset';
import SignUp from "../pages/Authentication/SignUp";

//pages
import Settings from '../pages/Pages/Profile/Settings/Settings';

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//APi Key
import APIKey from "../pages/APIKey/index";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
// import CreateUser from "../pages/Authentication/CreateUser";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";

import PrivecyPolicy from '../pages/Pages/PrivacyPolicy';

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// Users & Roles
import ManageAccess from "../pages/Users/ManageAccess";

// Mobile TopUp Page
import TopUpPage from "../pages/TopUpPage";

// SelfCare
import SelfCare from "../pages/SelfCare/SelfCare";

// Cell Broadcast Map
import GoogleMapComponent from "../pages/CellBroadcastMap/GoogleMapComponent";
import Transactions from "../pages/Billing/Transactions";
import PaymentMethods from "../pages/Billing/PaymentMethods";
import AddTransaction from "../pages/Billing/Transactions/components/AddTransaction";

const authProtectedRoutes = [
  // Users
  // { path: "/users", component: <CreateUser />},

  // Customer
  { path: "/customers", component: <CustomerList /> },
  { path: "/customer-create", component: <CustomerCreate /> },
  { path: "/customers/:id", component: <CustomerOverview />},

  // Services
  { path: "/services", component: <ServiceList /> },
  { path: "/service-create", component: <AddServiceToCustomer /> },

  // Products & Plans
  { path: "/products-items-list", component: <PlanList page={true} />},

  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  
  //Api Key
  { path: "/apps-api-key", component: <APIKey /> },

  //Invoices
  { path: "/apps-invoices-list", component: <InvoiceList initialFilters={{paid:[false]}}/> },
  { path: "/apps-invoices-details", component: <InvoiceDetails /> },
  { path: "/apps-invoices-create", component: <InvoiceCreate /> },

  // Billing
  {path: "/billing-invoices-outstanding", component: <InvoiceList initialFilters={{paid:[false]}}/>},
  {path: "/billing-transactions-create", component: <AddTransaction />},
  {path: "/billing-transactions-recent", component: <Transactions />},
  {path: "/billing-cards-expiring", component: <PaymentMethods />},

  // Inventory
  { path: "/inventory-items-template", component: <TemplateList /> },
  { path: "/inventory-items-template/:templateId", component: <TemplateList /> },
  { path: "/inventory-items-list", component: <InventoryList /> },
  { path: "/inventory-items-create", component: <InventoryCreate /> },

  // Provisioning
  { path: "/provisioning-recent-provision", component: <RecentProvisioningJobs /> },
  { path: "/provisioning-recent-failed", component: <FailedProvisioningJobs /> },
  { path: "/provisioning-recent-deprovision", component: <RecentDeprovisioningJobs /> },

  //Supports Tickets
  { path: "/apps-tickets-list", component: <ListView /> },
  { path: "/apps-tickets-details", component: <TicketsDetails /> },

  { path: "/pages-profile-settings", component: <Settings /> },

  { path: "/pages-privacy-policy", component: <PrivecyPolicy /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // Users & Roles
  { path: "/users-and-roles", component: <ManageAccess />},

  // Cell Broadcast Map
  { path: "/cell-broadcast-new", component: <GoogleMapComponent /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/customers" />,
  },
  { path: "*", component: <Navigate to="/customers" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password/:token", component: <ResetPasswordPage />},
  { path: "/signup", component: <SignUp />},

  // Mobile TopUp
  { path: "/dongle-topup", component: <TopUpPage />},

  // SelfCare
  { path: "/self-care/*", component: <SelfCare /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

];

export { authProtectedRoutes, publicRoutes };