//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

// CRM Auth
export const POST_JWT_LOGIN = "/crm/auth/login";
export const POST_JWT_REGISTER = "/crm/auth/register";
export const POST_JWT_FORGOT_PASSWORD = "/crm/auth/forgot_password";
export const POST_JWT_RESET_PASSWORD = "/crm/auth/reset_password";
export const POST_JWT_CHANGE_PASSWORD = "/crm/auth/change_password";
// export const POST_JWT_VERIFY_EMAIL = "/crm/auth/verify-email";
// export const POST_JWT_VERIFY_PHONE = "/crm/auth/verify-phone";
// export const POST_JWT_VERIFY_CODE = "/crm/auth/verify-code";
// export const POST_JWT_RESEND_CODE = "/crm/auth/resend-code";
export const POST_JWT_LOGOUT = "/crm/auth/logout";
export const POST_SIGNUP = "/crm/auth/signup";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// // Calendar
// export const GET_EVENTS = "/events";
// export const GET_CATEGORIES = "/categories";
// export const GET_UPCOMMINGEVENT = "/upcommingevents";
// export const ADD_NEW_EVENT = "/add/event";
// export const UPDATE_EVENT = "/update/event";
// export const DELETE_EVENT = "/delete/event";

// // Chat
// export const GET_DIRECT_CONTACT = "/chat";
// export const GET_MESSAGES = "/messages";
// export const ADD_MESSAGE = "add/message";
// export const GET_CHANNELS = "/channels";
// export const DELETE_MESSAGE = "delete/message";

// //Mailbox
// export const GET_MAIL_DETAILS = "/mail";
// export const DELETE_MAIL = "/delete/mail";

// // Ecommerce
// // Product
// export const GET_PRODUCTS = "/apps/product";
// export const DELETE_PRODUCT = "/apps/product";
// export const ADD_NEW_PRODUCT = "/apps/product";
// export const UPDATE_PRODUCT = "/apps/product";

// // Orders
// export const GET_ORDERS = "/apps/order";
// export const ADD_NEW_ORDER = "/apps/order";
// export const UPDATE_ORDER = "/apps/order";
// export const DELETE_ORDER = "/apps/order";

// // Customers
// export const GET_CUSTOMERS = "/apps/customer";
// export const ADD_NEW_CUSTOMER = "/apps/customer";
// export const UPDATE_CUSTOMER = "/apps/customer";
// export const DELETE_CUSTOMER = "/apps/customer";

// // Sellers
// export const GET_SELLERS = "/sellers";

// // Project list
// export const GET_PROJECT_LIST = "/project/list";

// // Task
// export const GET_TASK_LIST = "/apps/task";
// export const ADD_NEW_TASK = "/apps/task";
// export const UPDATE_TASK = "/apps/task";
// export const DELETE_TASK = "/apps/task";

// // CRM
// // Conatct
// export const GET_CONTACTS = "/apps/contact";
// export const ADD_NEW_CONTACT = "/apps/contact";
// export const UPDATE_CONTACT = "/apps/contact";
// export const DELETE_CONTACT = "/apps/contact";

// // Companies
// export const GET_COMPANIES = "/apps/company";
// export const ADD_NEW_COMPANIES = "/apps/company";
// export const UPDATE_COMPANIES = "/apps/company";
// export const DELETE_COMPANIES = "/apps/company";

// // Lead
// export const GET_LEADS = "/apps/lead";
// export const ADD_NEW_LEAD = "/apps/lead";
// export const UPDATE_LEAD = "/apps/lead";
// export const DELETE_LEAD = "/apps/lead";

// // Deals
// export const GET_DEALS = "/deals";

// // Crypto
// export const GET_TRANSACTION_LIST = "/transaction-list";
// export const GET_ORDRER_LIST = "/order-list";

// Invoice
// export const GET_INVOICES = "/apps/invoice";
// export const ADD_NEW_INVOICE = "/apps/invoice";
// export const UPDATE_INVOICE = "/apps/invoice";
// export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket";
export const ADD_NEW_TICKET = "/apps/ticket";
export const UPDATE_TICKET = "/apps/ticket";
export const DELETE_TICKET = "/apps/ticket";

// // kanban
// export const GET_TASKS = "/apps/tasks";
// export const ADD_TASKS = "/add/tasks";
// export const UPDATE_TASKS = "/update/tasks";
// export const DELETE_TASKS = "/delete/tasks";

// // Dashboard Analytics

// // Sessions by Countries
// export const GET_ALL_DATA = "/all-data";
// export const GET_HALFYEARLY_DATA = "/halfyearly-data";
// export const GET_MONTHLY_DATA = "/monthly-data";

// // Audiences Metrics
// export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
// export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
// export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = "/halfyearlyAudiencesMetrics-data";
// export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// // Users by Device
// export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
// export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
// export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
// export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// // Audiences Sessions by Country
// export const GET_TODAYSESSION_DATA = "/todaySession-data";
// export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
// export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
// export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// // Dashboard CRM

// // Balance Overview
// export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
// export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
// export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
// export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// // Deal type
// export const GET_TODAYDEAL_DATA = "/todayDeal-data";
// export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
// export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
// export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// // Sales Forecast

// export const GET_OCTSALES_DATA = "/octSales-data";
// export const GET_NOVSALES_DATA = "/novSales-data";
// export const GET_DECSALES_DATA = "/decSales-data";
// export const GET_JANSALES_DATA = "/janSales-data";

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data";
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// // Dashboard Crypto
// // Portfolio
// export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
// export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
// export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// // Market Graph
// export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
// export const GET_YEARMARKET_DATA = "/yearMarket-data";
// export const GET_MONTHMARKET_DATA = "/monthMarket-data";
// export const GET_WEEKMARKET_DATA = "/weekMarket-data";
// export const GET_HOURMARKET_DATA = "/hourMarket-data";

// // Dashboard Crypto
// // Project Overview
// export const GET_ALLPROJECT_DATA = "/allProject-data";
// export const GET_MONTHPROJECT_DATA = "/monthProject-data";
// export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
// export const GET_YEARPROJECT_DATA = "/yearProject-data";

// // Project Status
// export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
// export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
// export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
// export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// // Dashboard NFT
// // Marketplace
// export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
// export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
// export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
// export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// // Project
// export const ADD_NEW_PROJECT = "/add/project";
// export const UPDATE_PROJECT = "/update/project";
// export const DELETE_PROJECT = "/delete/project";

// // Pages > Team
// export const GET_TEAMDATA = "/teamData";
// export const DELETE_TEAMDATA = "/delete/teamData";
// export const ADD_NEW_TEAMDATA = "/add/teamData";
// export const UPDATE_TEAMDATA = "/update/teamData";

// // File Manager
// // Folder
// export const GET_FOLDERS = "/folder";
// export const DELETE_FOLDER = "/delete/folder";
// export const ADD_NEW_FOLDER = "/add/folder";
// export const UPDATE_FOLDER = "/update/folder";

// // File
// export const GET_FILES = "/file";
// export const DELETE_FILE = "/delete/file";
// export const ADD_NEW_FILE = "/add/file";
// export const UPDATE_FILE = "/update/file";

// // To do
// export const GET_TODOS = "/todo";
// export const DELETE_TODO = "/delete/todo";
// export const ADD_NEW_TODO = "/add/todo";
// export const UPDATE_TODO = "/update/todo";

// // To do Project
// export const GET_PROJECTS = "/projects";
// export const ADD_NEW_TODO_PROJECT = "/add/project";

// //JOB APPLICATION
// export const GET_APPLICATION_LIST = "/application-list";

//JOB APPLICATION
export const GET_API_KEY = "/api-key";

// CRM Customers
export const GET_CUSTOMERS = "/crm/customer/";
export const ADD_NEW_CUSTOMER = "/crm/customer/";
export const UPDATE_CUSTOMER = "/crm/customer/customer_id/";
export const DELETE_CUSTOMER = "/crm/customer/customer_id/";
export const GET_CUSTOMER_BY_ID = "/crm/customer/customer_id/";

// CRM Customers
export const GET_CONTACTS = "/crm/contact/";
export const ADD_NEW_CONTACT = "/crm/contact/";
export const UPDATE_CONTACT = "/crm/contact/contact_id/";
export const DELETE_CONTACT = "/crm/contact/contact_id/";
export const GET_CONTACT_BY_ID = "/crm/contact/contact_id/";
export const SEND_AUTH_MESSAGE_TO_CONTACT = "/crm/contact/contact_id/authorize_contact/";
export const GET_CONTACTS_BY_CUSTOMER_ID = "/crm/contact/customer_id/";

// CRM Site
export const GET_SITES_BY_CUSTOMER_ID = "/crm/site/customer_id/";
export const ADD_NEW_SITE = "/crm/site/";
export const UPDATE_SITE = "/crm/site/site_id/";
export const DELETE_SITE = "/crm/site/site_id/";
export const GET_SITE_BY_ID = "/crm/site/site_id/";

// CRM Transactions
export const GET_TRANSACTIONS = "/crm/transaction/";
export const ADD_NEW_TRANSACTION = "/crm/transaction/";
export const GET_TRANSACTIONS_BY_CUSTOMER_ID = "/crm/transaction/customer_id/";
export const GET_TRANSACTIONS_BY_INVOICE_ID = "/crm/transaction/invoice_id/";
export const DELETE_TRANSACTION = "/crm/transaction/transaction_id/";
export const PROCESS_JSON_RPC_EVENT = "/crm/transaction/event_json_map_http";
export const GET_TRANSACTION_STATS = "/crm/transaction/stats";
export const GET_TRANSACTION_STATS_BY_CUSTOMER_ID = "/crm/transaction/{customer_id}/stats";

// CRM Attributes
export const GET_ATTRIBUTES = "/crm/attribute/";
export const ADD_NEW_ATTRIBUTE = "/crm/attribute/";
export const UPDATE_ATTRIBUTE = "/crm/attribute/attribute_id/";
export const DELETE_ATTRIBUTE = "/crm/attribute/attribute_id/";
export const GET_ATTRIBUTE_BY_ID = "/crm/attribute/attribute_id/";
export const GET_ATTRIBUTES_BY_CUSTOMER_ID = "/crm/attribute/customer_id/";

// CRM Services
export const GET_SERVICES = "/crm/service/";
export const ADD_NEW_SERVICE = "/crm/service/";
export const UPDATE_SERVICE = "/crm/service/{service_id}";
export const DELETE_SERVICE = "/crm/service/{service_id}";
export const GET_SERVICE_BY_ID = "/crm/service/service_id/{service_id}";
export const GET_SIMPLE_SERVICE_BY_ID = "/crm/service/{service_id}";
export const GET_SERVICES_BY_CUSTOMER_ID = "/crm/service/customer_id/{customer_id}";
export const DELETE_ACTION_PLAN_FROM_SERVICE_UUID = "/crm/service/action/service_uuid/{service_uuid}/{action_plan}";
export const GET_SERVICES_BY_MSISDN = "/crm/service/mobile/get_by_msisdn/{msisdn}";
export const DEACTIVATE_SERVICE_BY_UUID = "/crm/service/service_uuid/{uuid}";

// CRM Stripe
export const GET_STRIPE_OBJECTS = "/crm/stripe/";
export const ADD_NEW_STRIPE_OBJECT = "/crm/stripe/";
export const DELETE_STRIPE_OBJECT = "/crm/stripe/customer_stripe_id/";
export const GET_STRIPE_OBJECTS_BY_CUSTOMER_ID = "/crm/stripe/customer_id/";
export const ADD_STRIPE_PAYMENT_FOR_AN_INVOICE = "/crm/stripe/process_payment_invoice/{invoice_id}/{token_id}";
export const CHARGE_STRIPE_PAYMENT = "/crm/stripe/charge_card/{token_id}";
export const CREATE_PAYMENT_INTENT = "crm/stripe/create_payment_intent";

// CRM Communications
export const GET_COMMUNICATIONS_BY_CUSTOMER_ID = "/crm/communication/customer_id/{customer_id}";
export const ADD_NEW_COMMUNICATION = "/crm/communication/";
export const GET_COMMUNICATION_BY_ID = "/crm/communication/communication_id/{communication_id}";
export const SEND_INVOICE_EMAIL_TO_CUSTOMER = 'crm/communication/customer/invoice/{invoice_id}';

// CRM Inventory
export const GET_ALL_INVENTORY_ITEMS = "/crm/inventory/";
export const CREATE_INVENTORY_ITEM = "/crm/inventory/";
export const GET_INVENTORY_BY_CUSTOMER_ID = "/crm/inventory/customer_id/{customer_id}";
export const DELETE_INVENTORY = "/crm/inventory/inventory_id/{inventory_id}";
export const GET_INVENTORY_BY_ID = "/crm/inventory/inventory_id/{inventory_id}";
export const UPDATE_INVENTORY = "/crm/inventory/inventory_id/{inventory_id}";
export const GET_INVENTORY_BY_ITEM_NAME = "/crm/inventory/item/{item_name}";
export const GET_INVENTORY_BY_TEMPLATE_ID = "/crm/inventory/item_by_template_id/{inventory_template_id}";
export const IS_INVENTORY_ITEM_AVAILABLE_BY_ID = "/crm/inventory/is_item_available_by_id/{inventory_id}";
export const IS_INVENTORY_ITEM_AVAILABLE_BY_SERIAL1 = "/crm/inventory/is_item_available_by_serial1/{serial1}";

// CRM Inventory Templates
export const GET_ALL_INVENTORY_TEMPLATES = "/crm/inventory/item_template/";
export const CREATE_INVENTORY_TEMPLATE_ITEM = "/crm/inventory/item_template/";
export const DELETE_INVENTORY_TEMPLATE_BY_ID = "/crm/inventory/item_template/{inventory_template_id}";
export const GET_INVENTORY_TEMPLATE_BY_ID = "/crm/inventory/item_template/{inventory_template_id}";
export const UPDATE_INVENTORY_TEMPLATE_BY_ID = "/crm/inventory/item_template/{inventory_template_id}";

// CRM Inventory Totals
export const GET_TOTALS_OF_INVENTORY_ITEMS = "/crm/inventory/totals/";

// CRM Invoices
export const GET_INVOICES = "/crm/invoice/";
export const ADD_NEW_INVOICE = "/crm/invoice/";
export const UPDATE_INVOICE = "/crm/invoice/invoice_id/{invoice_id}";
export const DELETE_INVOICE = "/crm/invoice/invoice_id/{invoice_id}";
export const GET_INVOICE_BY_ID = "/crm/invoice/invoice_id/metadata/{invoice_id}";
export const GET_INVOICES_BY_CUSTOMER_ID = "/crm/invoice/customer_id/{customer_id}";
export const GET_INVOICE_PDF_BY_ID = "/crm/invoice/invoice_id/pdf/{invoice_id}";
export const GET_INVOICE_STATS = "/crm/invoice/stats";
export const GET_INVOICE_STATS_BY_CUSTOMER_ID = "/crm/invoice/{customer_id}/stats";

// CRM Cell Broadcasts
export const CREATE_CBC_MESSAGE = "/crm/cbc/";
export const GET_ALL_CBC_ITEMS = "/crm/cbc/";
export const DELETE_CBC_MESSAGE = "/crm/cbc/{cbc_message_id}";

// CRM OAM
export const SEARCH_DATABASE = "/crm/oam/search/{searchQuery}";
export const GET_DATABASE_STATS = "/crm/oam/stats";
export const GET_USAGE = "/crm/oam/get_usage";
export const FIND_CUSTOMER_BY_IMSI= "/crm/oam/find_customer/{imsi}"
export const FIND_SERVICE_BY_IMSI= "/crm/oam/find_service/{imsi}"
export const LIST_IMSI_BY_CUSTOMER_ID= "/crm/oam/list_imsi/customer_id/{customer_id}"

// CRM Products and Plans
export const CREATE_NEW_PRODUCT = "/crm/product/";
export const GET_ALL_PRODUCTS = "/crm/product/";
export const UPDATE_PRODUCT = "/crm/product/product_id/{product_id}";
export const DELETE_PRODUCT = "/crm/product/product_id/{product_id}";
export const GET_PRODUCT_BY_ID = "/crm/product/product_id/{product_id}";

// CRM Provisioning
export const CREATE_NEW_PROVISIONING_JOB = "/crm/provision/";
export const UPDATE_PROVISIONING_RECORD = "/crm/provision/";
export const CREATE_NEW_PROVISIONING_RECORD = "/crm/provision/";
export const GET_ALL_PROVISIONING_JOBS = "/crm/provision/";
export const GET_AVAILABLE_PLAYBOOKS = "/crm/provision/playbooks";
export const GET_PROVISIONING_STATUS = "/crm/provision/provisioning_id/{provisioning_id}";
export const CREATE_SIMPLE_PROVISION_JOB = "/crm/provision/simple_provision_addon/service_id/{service_id}/product_id/{product_id}";

// CRM Activities
export const CREATE_ACTIVITY = "/crm/activity/";
export const DELETE_ACTIVITY = "/crm/activity/activity_id/{activity_id}";
export const GET_ACTIVITY_BY_ID = "/crm/activity/activity_id/{activity_id}";
export const UPDATE_ACTIVITY = "/crm/activity/activity_id/{activity_id}";
export const GET_ACTIVITIES_BY_CUSTOMER_ID = "/crm/activity/customer_id/{customer_id_to_find}";

// CRM Users
export const GET_ALL_USERS = "/crm/auth/users";
export const CREATE_NEW_USER = "/crm/auth/users";
export const DELETE_USER = "/crm/auth/users/{user_id}";
export const GET_USER_BY_ID = "/crm/auth/users/{user_id}";
export const UPDATE_USER = "/crm/auth/users/{user_id}";
export const SEND_USER_WELCOME_EMAIL = "/crm/auth/users/{user_id}/send_welcome_email";

// Roles
export const GET_ALL_ROLES = "/crm/auth/roles";
export const CREATE_NEW_ROLE = "/crm/auth/roles";
export const DELETE_ROLE = "/crm/auth/roles/{role_id}";
export const GET_ROLE_BY_ID = "/crm/auth/roles/{role_id}";
export const UPDATE_ROLE = "/crm/auth/roles/{role_id}";

// Permissions
export const GET_ALL_PERMISSIONS = "/crm/auth/permissions";
export const CREATE_NEW_PERMISSION = "/crm/auth/permissions";
export const DELETE_PERMISSION = "/crm/auth/permissions/{permission_id}";
export const GET_PERMISSION_BY_ID = "/crm/auth/permissions/{permission_id}";
export const UPDATE_PERMISSION = "/crm/auth/permissions/{permission_id}";