import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import * as moment from "moment";
import CountUp from "react-countup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ErrorModal from "../../Components/Common/ErrorModal";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";

//Import Icons
import FeatherIcon from "feather-icons-react";
import InvoiceWidgetsData from "./components/InvoiceWidgetsData";

//Import actions
import {
  getInvoicesByCustomerId as onGetInvoicesByCustomerId,
  deleteInvoice as onDeleteInvoice,
  addNewInvoice as onAddNewInvoice,
  resetInvoiceStates as onResetInvoiceStates,
  sendInvoiceEmailToCustomer as onSendInvoiceEmailToCustomer,
  getTransactionsByCustomerId as onGetTransactionsByCustomerId,
  getCustomers as onGetCustomers,
  getInvoices as onGetInvoices,
} from "../../slices/thunks";

import {
  getInvoicePdfById as getInvoicePdfByIdApi,
} from "../../helpers/backend_helper";

//redux
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createSelector } from "reselect";

import PaymentModal from "./components/PaymentModal";
import TableCommon from "../../Components/Common/TableCommon";

import "./css/custom.css";

const InvoiceList = ({customer = null, showWidgets = true, type = null, initialFilters = {}}) => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state.Invoice;
  const selectinvoiceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      invoicesList: state.invoices,
      isInvoiceSuccess: state.isInvoiceSuccess,
      error: state.error,
    })
  );

  const {
    invoicesList, isInvoiceSuccess, error
  } = useSelector(selectinvoiceProperties);
  onGetInvoicesByCustomerId
  const [invoices, setInvoices] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(null);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState([]);
  const [selectCustomerValidation, setSelectCustomerValidation] = useState(false);

  const togglePaymentModal = () => setPaymentModal(!paymentModal);
  const toggleGenerateInvoice = () => setGenerateInvoice(!generateInvoice);

  const today = new Date();
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const [newInvoice, setNewInvoice] = useState({
    customer_id: customer?.customer_id,
    title: "",
    start_date: twoWeeksAgo.toISOString().split('T')[0],
    end_date: today.toISOString().split('T')[0],
    due_date: today.toISOString().split('T')[0],
    filter: "",
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalRecords: 0,
    totalPages: 0,
  });
  const [isInvoiceFetched, setIsInvoiceFetched] = useState(false);
  const [formattedInvoices, setFormattedInvoices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'invoice_id', direction: 'desc' });

  const loadOptions = (inputValue, callback) => {
    dispatch(onGetCustomers({ searchTerm: inputValue }))
        .then((response) => {
            const options = response.payload.data
                .map(customer => ({ value: customer.customer_id, label: customer.customer_name }));
            
            callback(options);
        });
  };

  const [filterOptions, setFilterOptions] = useState({
        void: {
            options: [
                { value: true, label: 'Void' },
                { value: false, label: 'Not Void' }
            ]
        },
        paid: {
            options: [
                { value: true, label: 'Paid' },
                { value: false, label: 'Not yet Paid' }
            ]
        },
    });

    const [filters, setFilters] = useState(initialFilters);

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: [value],
        }));
    };

    const applyFilters = () => {
        fetchRecords();
    };

    const resetFilters = () => {
        if (initialFilters) {
            setFilters(initialFilters);
        } else {
          setFilters({});
        }
    };

    // for use in reset filters to refetch inventory
    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            fetchRecords();
        }
    }, [filters]);

  useEffect(() => {
    if (!isInvoiceFetched) {
      fetchRecords();
      setIsInvoiceFetched(true);
    }
  }, [dispatch]);

  const fetchRecords = (page = pagination.currentPage, perPage = pagination.itemsPerPage, sort = sortConfig.key, sortOrder = sortConfig.direction, search = searchTerm) => {

    if (sort === 'price') {
      sort = 'retail_cost';
    }

    const customerId = customer?.customer_id;
    if (customerId) {
      dispatch(onGetInvoicesByCustomerId({customerId, page, perPage, sort, sortOrder, searchTerm: search, filters}))
        .then(() => {
          setIsInvoiceFetched(true);
          setSortConfig({ key: sort, direction: sortOrder });
          setSearchTerm(searchTerm);
        });
    } else {
      dispatch(onGetInvoices({page, perPage, sort, sortOrder, searchTerm: search, filters}))
        .then(() => {
          setIsInvoiceFetched(true);
          setSortConfig({ key: sort, direction: sortOrder });
          setSearchTerm(searchTerm);
        });
    }
  };

  const refreshTransactions = () => {
    dispatch(onGetTransactionsByCustomerId({customerId: customer?.customer_id}));
  }

  const refreshInvoiceList = () => {
    fetchRecords(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
  };

  useEffect(() => {
    setInvoices(invoicesList?.data ?? []);
    setPagination({
      currentPage: invoicesList?.pagination?.page ?? 1,
      itemsPerPage: invoicesList?.pagination?.per_page ?? 10,
      totalRecords: invoicesList?.pagination?.total_records ?? 0,
      totalPages: invoicesList?.pagination?.total_pages ?? 0,
    });
  }, [invoicesList]);

  // Delete Data
  const onClickDelete = (invoice) => {
    setInvoiceToDelete(invoice);
    setDeleteModal(true);
  };

  const handleDeleteInvoice = () => {
    if (invoiceToDelete) {
      dispatch(onDeleteInvoice(invoiceToDelete.invoice_id))
        .then((resultAction) => {
          if (resultAction.payload && resultAction.payload.status === 'success') {
            toast.success('Invoice deleted successfully', { autoClose: 3000 });
            fetchRecords();
            refreshTransactions();
            refreshInvoiceList();
          } else {
            toast.error(resultAction?.payload || 'Invoice deletion failed', { autoClose: 3000 });
          }
        })
        .catch((error) => {
          toast.error('An unexpected error occurred', { autoClose: 3000 });
        })
        .finally(() => {
          setInvoiceToDelete(null);
          setDeleteModal(false);
        });
    }
  };

  const handleEmailInvoice = (invoiceId) => {
    dispatch(onSendInvoiceEmailToCustomer(invoiceId))
      .then(() => {
        toast.success('Invoice email successfully sent', { autoClose: 3000 });
      })
      .catch((error) => {
        toast.error('Invoice email sending failed', { autoClose: 3000 });
        console.log('Error sending Invoice email: ', error);
      });
  };

  const handleGenerateInvoiceSubmit = () => {
    dispatch(onAddNewInvoice(newInvoice)).then(() => {
      toast.success('Invoice generated successfully', { autoClose: 3000 });
      fetchRecords(pagination.currentPage, pagination.itemsPerPage, sortConfig.key, sortConfig.direction, searchTerm);
      refreshTransactions();
    }).catch((error) => {
      toast.error("Error: " + error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    });

    handleCloseGenerateInvoice();
  };

  const handleCloseGenerateInvoice = () => {
    setGenerateInvoice(false);
    clearNewInvoice();
  };

  const clearNewInvoice = () => {
    setNewInvoice({
      customer_id: customer?.customer_id,
      title: "",
      start_date: twoWeeksAgo.toISOString().split('T')[0], // two weeks ago
      end_date: today.toISOString().split('T')[0], // today's date
      due_date: today.toISOString().split('T')[0], // today's date
      filter: "",
    });
  };

  const onDownloadInvoice = async (invoiceId) => {
    try {
      const response = await getInvoicePdfByIdApi(invoiceId);
      // Create a link to download the blob
      const fileURL = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileURL;

      // Pad the invoiceId with leading zeros to ensure it's at least 5 digits long
      const paddedInvoiceId = String(invoiceId).padStart(5, '0');

      link.download = `GFS_${paddedInvoiceId}.pdf`;
      link.click();

      // Clean up by revoking the object URL after the link has been clicked
      URL.revokeObjectURL(fileURL);

      toast.success("Invoice Downloaded Successfully", { autoClose: 3000 });
    } catch (error) {
      toast.error("Invoice Download Failed", { autoClose: 3000 });
    }
  };

  const onPayInvoice = (invoiceId) => {
    const currentInvoice = invoices.find((invoice) => invoice.invoice_id === invoiceId);
    setInvoice(currentInvoice);
    setPaymentModal(true);
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteInvoice(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  useEffect(() => {
    if (invoices) {
      const formattedInvoices = invoices.map((invoice) => {
        let formattedItems = {
          id: invoice.invoice_id,
          invoice_id: invoice.invoice_id,
          customer_id: customer ? null : invoice.customer_id,
          title: <span className={invoice.void ? 'void-invoice' : undefined}>{invoice.void && 'Void: '}{invoice.title}</span>,
          period: `${handleValidDate(invoice.start_date)} - ${handleValidDate(invoice.end_date)}`,
          due_date: handleValidDate(invoice.due_date),
          created: handleValidDate(invoice.created),
          retail_cost: null,
          price: null,
          paid: invoice.paid ? 'Paid' : 'Unpaid',
          actions: !invoice.void && (
            <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item edit">
                    <Link
                        title={'Download Invoice'}
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDownloadInvoice(invoice.invoice_id);
                        }}
                    >
                    <i className="ri-download-2-fill fs-16"></i>
                    </Link>
                </li>
                {(!invoice.void && type !== 'self-care') && (
                  <li className="list-inline-item">
                      <Link
                          title={'Delete'}
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={(e) => {
                              e.stopPropagation();
                              if (invoice.paid) {
                                  setErrorModalMessage('Paid invoice cannot be deleted.');
                                  setErrorModal(true);
                              } else {
                                  onClickDelete(invoice);
                              }
                          }}
                      >
                          <i className="ri-delete-bin-5-fill fs-16"></i>
                      </Link>
                  </li>
                )}
                {!invoice.paid && !invoice.void && (
                    <li className="list-inline-item">
                        <Link
                            title={'Pay Invoice'}
                            className="text-success d-inline-block"
                            onClick={(e) => {
                                e.stopPropagation();
                                onPayInvoice(invoice.invoice_id);
                            }}
                        >
                            <i className="ri-money-dollar-box-fill fs-16"></i>
                        </Link>
                    </li>
                )}
                <li className="list-inline-item">
                    <Link
                        title={'Send Invoice Email'}
                        className="text-info d-inline-block"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEmailInvoice(invoice.invoice_id);
                        }}
                    >
                        <i className="ri-mail-send-fill fs-16"></i>
                    </Link>
                </li>
            </ul>
        )
        };

        if (type !== 'self-care') {
          formattedItems.retail_cost = <span className={invoice.void ? 'void-invoice' : undefined}>{invoice.retail_cost}</span>;
          // remove price from invoice list
          delete formattedItems.price;
        } else {
          formattedItems.price = <span className={invoice.void ? 'void-invoice' : undefined}>{invoice.retail_cost}</span>;
          // remove retail cost from invoice list
          delete formattedItems.retail_cost;
        }

        if (customer) {
          delete formattedItems.customer_id;
        }

        return formattedItems;
      });
      setFormattedInvoices(formattedInvoices);
    }
  }, [invoices]);

  useEffect(() => {
    return () => {
      dispatch(onResetInvoiceStates());
      setInvoices([]);
      setFormattedInvoices([]);
    };
  }, []);

  document.title = "Invoice List | Omnitouch CRM";

  return (
    <React.Fragment>
      <div>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDeleteInvoice()}
          onCloseClick={() => {setInvoice(null);setDeleteModal(false);}}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <ErrorModal
          show={errorModal}
          message={errorModalMessage}
          onCloseClick={() => {setInvoice(null);setErrorModal(false);}}
        />
        <Container fluid>
          {!customer && (
            <BreadCrumb title="Invoice List" pageTitle="Invoices" />
          )}
          <h4 className="card-title mb-4">Invoices</h4>

          {showWidgets && (
            <InvoiceWidgetsData customer={customer}/>
          )}

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Invoices</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        {(isMultiDeleteButton && type !== 'self-care') && <button className="btn btn-primary me-1"
                          onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button>}
                        {type !== 'self-care' && (
                          <Button
                            className="btn btn-success"
                            onClick={toggleGenerateInvoice}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Generate Proforma Invoice
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    <TableCommon
                        title="Invoices"
                        noHeader={true}
                        data={formattedInvoices}
                        pay_invoice={onPayInvoice}
                        download_invoice={onDownloadInvoice}
                        pagination={pagination}
                        sortConfig={sortConfig}
                        fetchRecords={fetchRecords}
                        sortableColumns={['title', 'due_date', 'created', 'retail_cost', 'price', 'paid', 'customer_id']}
                        filters={{
                            filterOptions: filterOptions,
                            handleFilterChange: handleFilterChange,
                            applyFilters: applyFilters,
                            resetFilters: resetFilters
                        }}
                        filterData={filters}
                    />
                    <ToastContainer closeButton={false} limit={1} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={generateInvoice} toggle={handleCloseGenerateInvoice}>
            <ModalHeader toggle={handleCloseGenerateInvoice}>Generate Invoice</ModalHeader>
            <ModalBody>
              {customer === null && (
                <>
                  <Label className='mb-2'>Search Customers</Label>
                  <AsyncSelect
                      loadOptions={loadOptions}
                      onChange={(selectedOption) => {
                          setSelectedCustomerId(selectedOption);
                          setNewInvoice({ ...newInvoice, customer_id: selectedOption.value });
                          setSelectCustomerValidation(selectedOption.value !== null);
                      }}
                      styles={{
                          menu: provided => ({ ...provided, zIndex: 2 })
                      }}
                  />
                  {!selectCustomerValidation && <div className="alert alert-danger">Please select a customer</div>}
                </>
              )}
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={newInvoice.title}
                  onChange={(e) => setNewInvoice({ ...newInvoice, title: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={newInvoice.start_date}
                  onChange={(e) => setNewInvoice({ ...newInvoice, start_date: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={newInvoice.end_date}
                  onChange={(e) => setNewInvoice({ ...newInvoice, end_date: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Due Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={newInvoice.due_date}
                  onChange={(e) => setNewInvoice({ ...newInvoice, due_date: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Filter</label>
                <input
                  type="text"
                  className="form-control"
                  value={newInvoice.filter}
                  onChange={(e) => setNewInvoice({ ...newInvoice, filter: e.target.value })}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleCloseGenerateInvoice}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleGenerateInvoiceSubmit}
              >
                Generate Invoice
              </button>
            </ModalFooter>
          </Modal>

          <PaymentModal
            isOpen={paymentModal}
            toggle={togglePaymentModal}
            selectedInvoice={invoice}
            onSuccess={() => {
              refreshInvoiceList();
              refreshTransactions();
              setPaymentModal(false);
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;